import React, { ReactNode } from "react"
import { Badge } from "antd"
import { useCart } from "./useCart"
import Link from "next/link"
import { css } from "@emotion/react"

const WithCartQuantity: React.FC<Props> = ({ children, href }) => {
  const { cart } = useCart()
  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0)

  return (
    <>
      <Link href={href}>{children}</Link>

      <Badge
        count={totalItems}
        css={css`
          transform: translate(0, -20px);
        `}>
        {" "}
      </Badge>
    </>
  )
}

interface Props {
  children: ReactNode
  href: string
}

export default WithCartQuantity
