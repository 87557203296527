export interface Reason {
  title: string
  description: string
  long_description: string
  seoDescription: string
  keywords: string
  slug: string
}

export const reasons: Reason[] = [
  {
    title: "Z produktivního hraní k vzdělávání",
    description: "Přetvořte dětskou zálibu v počítačové hry v produktivní dovednost.",
    long_description:
      "Pokud vaše dítě tráví hodně času hraním počítačových her, přihlášení na kurz programování nebo 3D tisku může tuto zálibu přeměnit na vzdělávací příležitost. Naučí se, jak hry a aplikace fungují 'pod kapotou', a získá praktické dovednosti, které mohou v budoucnu využít ve své kariéře.",
    slug: "z-hrani-k-vzdelavani",
    keywords: "programování pro děti, 3D tisk, počítačové hry, vzdělávací hry",
    seoDescription:
      "Přetvořte dětskou zálibu v počítačové hry v produktivní dovednost. Naše kurzy programování a 3D tisku učí, jak hry fungují a rozvíjí praktické dovednosti."
  },
  {
    title: "Rozvoj kreativity a logického myšlení",
    description: "Podpořte kreativitu a logické myšlení vašich dětí.",
    long_description:
      "Online kurzy programování a 3D tisku stimulují kreativní a logické myšlení dětí. Programování vyzývá děti, aby přemýšlely o řešení problémů a vytvářely vlastní projekty od základů. 3D tisk pak rozvíjí prostorové vnímání a umožňuje dětem převést své digitální návrhy do fyzické reality.",
    slug: "kreativita-logika",
    keywords: "kreativní myšlení, logické řešení problémů, programování, 3D tisk",
    seoDescription:
      "Podpořte kreativitu a logické myšlení vašich dětí s našimi online kurzy programování a 3D tisku. Děti se naučí řešit problémy a tvořit vlastní projekty."
  },
  {
    title: "Příprava na budoucí kariéru",
    description: "Zajistěte dětem náskok v technologických oborech.",
    long_description:
      "Svět se rychle mění a technologie hrají stále větší roli v našich životech i pracovních příležitostech. Přihlášením vašeho dítěte na online kurz programování nebo 3D tisku mu dáváte náskok v oblastech, které budou v budoucnu klíčové. Nejenže si rozšíří dovednosti a zájmy, ale také si vytvoří pevný základ pro budoucí studium nebo kariéru.",
    slug: "priprava-na-karieru",
    keywords: "budoucí kariéra, technologie, programování, 3D tisk",
    seoDescription:
      "Připravte své děti na budoucnost s technologiemi prostřednictvím našich kurzů programování a 3D tisku. Získají náskok v klíčových oblastech pro budoucí kariéru."
  },
  {
    title: "Flexibilita a přístupnost odkudkoli",
    description: "Umožněte dětem učit se flexibilně z domova.",
    long_description:
      "Online kurzy programování a 3D tisku nabízejí jedinečnou flexibilitu - vaše děti se mohou učit z pohodlí domova nebo odkudkoli s internetovým připojením. Tento způsob vzdělávání umožňuje rodinám snadno začlenit učení do svých plánů bez nutnosti dopravy do vzdělávacích zařízení.",
    slug: "flexibilita-pri-uceni",
    keywords: "online vzdělávání, flexibilita",
    seoDescription:
      "Nabízíme flexibilní a dostupné online kurzy, které umožňují dětem učit se odkudkoli. Kurzy jsou přístupné a vhodné pro všechny s internetovým připojením."
  },
  {
    title: "Zvýšení sebevědomí při řešení problémů",
    description: "Podpořte sebevědomí vašich dětí řešením reálných problémů.",
    long_description:
      "Programování a 3D tisk poskytují dětem praktické zkušenosti s řešením problémů, což vede ke zvýšení jejich sebevědomí. Když děti vidí, jak jejich projekty ožívají, cítí hrdost a uznání svých schopností. Tento proces jim nejen pomáhá rozvíjet důležité dovednosti, ale také posiluje jejich věru v sebe sama a v to, co mohou dosáhnout.",
    slug: "zvyseni-sebevedomi",
    keywords: "řešení problémů, sebevědomí, programování, 3D tisk",
    seoDescription:
      "Zvyšte sebevědomí vašich dětí prostřednictvím praktických zkušeností s řešením problémů. Programování a 3D tisk rozvíjí důležité dovednosti a věru v sebe."
  },
  {
    title: "Rozvoj digitální gramotnosti",
    description: "Připravte děti na digitální budoucnost s kurzy IT.",
    long_description:
      "Digitální gramotnost je nezbytná pro úspěch v moderním světě. Přihlášením vašich dětí na online kurzy programování nebo 3D tisku jim pomůžete rozvíjet a posilovat tyto klíčové dovednosti. Děti se naučí základy kódování, digitálního designu a myšlení, což je připraví na budoucnost, kde budou tyto dovednosti stále důležitější.",
    slug: "rozvoj-digitalni-gramotnosti",
    keywords: "digitální gramotnost, programování, 3D tisk, budoucnost",
    seoDescription:
      "Rozvíjejte digitální gramotnost vašich dětí s kurzy programování a 3D tisku. Naučí se základy kódování a digitálního designu, což je připraví na budoucnost."
  },
  {
    title: "Zábavný a interaktivní způsob učení",
    description: "Zábava při učení s online kurzami programování.",
    long_description:
      "Online kurzy programování a 3D tisku jsou navrženy tak, aby byly nejen vzdělávací, ale také zábavné a interaktivní. Děti se učí prostřednictvím hry a projektů, které je zapojují a motivují k dalšímu učení. Tento způsob učení je mnohem efektivnější a zábavnější než tradiční metody, a děti tak mohou rozvíjet své dovednosti, aniž by se nudily.",
    slug: "zabavne-uceni",
    keywords: "zábavné učení, interaktivní kurzy, programování, 3D tisk",
    seoDescription:
      "Učení se stává zábavnějším a interaktivnějším s našimi kurzy programování a 3D tisku. Děti se učí prostřednictvím hry a projektů, které je zapojují a motivují."
  },
  {
    title: "Překonání technologické bariéry",
    description: "Demystifikujte technologie pro vaše děti raným vzděláváním.",
    long_description:
      "Mnoho dětí a rodičů považuje technologie za složité nebo nedosažitelné. Online kurzy programování a 3D tisku nabízí jednoduchý a srozumitelný způsob, jak překonat tyto bariéry. Děti se učí, že technologie nejsou jen pro 'experty' a že mohou být kreativní a inovativní s nástroji, které mají k dispozici.",
    slug: "prekonani-technologicke-bariery",
    keywords: "technologická bariéra, programování pro děti, 3D tisk, vzdělávání",
    seoDescription:
      "Pomáháme dětem překonat technologické bariéry a objevovat svět programování a 3D tisku. Kurzy učí, že technologie nejsou jen pro 'experty', ale pro každého."
  }
]
